<template>
  <div class="flex items-center justify-center" style="height: 100vh">
    <Loader v-if="loader" />
    <div v-if="showAgreement" class="disclaimerDiv flex rounded p-4 w-10/12 overflow-auto"
      style="max-height: 90vh; width: 100%">
      <div class="w-full">
        <h2 class="pageHeading border-b border-blueGray-500 pb-2">
          {{ docType }}
        </h2>
        <div class="px-3">
          <div class="disclaimer_body pt-5">
            <p class="mb-3 text-sm">
              I hereby acknowledge and agree to the following {{ docType }}
            </p>

            <div v-if="iframeSrc_Agreement">
              <iframe ref="iframe" id="agreement_ref" :src="iframeSrc_Agreement" frameborder="0" width="100%"></iframe>
            </div>
            <div class="pt-4">
              <div class="flex items-center text-sm pb-1">
                <button class="mr-1 border-0 focus:outline-none bg-transparent underline"
                  style="color: var(--activeTextColor)" type="button" @click="downloadAgreement">
                  Click here
                </button>
                to download the {{ docType }}
              </div>
              <div>
                <input id="terms_checked" type="checkbox" v-model="terms_checked" class="__disclaimer_agree_checkbox" />
                <label for="terms_checked" class="ml-2 text-sm">
                  I agree
                </label>
              </div>
              <div>
                <p class="text-xs pt-1">
                  Note: If you checked "I agree" and clicked on "Agree" means
                  you are accepted our terms & conditions and signing to the
                  {{ docType }}.
                </p>
              </div>
            </div>
          </div>
          <div class="disclaimer_footer flex items-center pt-2" style="gap: 10px">
            <div>
              <button
                class="agreebtn text-white active:bg-blueGray-600 text-xs font-semibold uppercase px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click="agreement_agreed">
                agree
              </button>
              <a class="hidden" href="" id="toDownload_Agreement"></a>
            </div>
            <div>
              <button
                class="cancelbtn border active:bg-blueGray-600 text-xs font-semibold uppercase px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click="$emit('disagreed')">
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="!showAgreement"
      class="disclaimerDiv flex rounded p-4 w-10/12 overflow-auto"
      style="max-height: 90vh; width: 100%"
    >
      <div class="w-full">
        <h2 class="pageHeading border-b border-blueGray-500 pb-2">
          Disclaimer
        </h2>
        <div class="px-3">
          <div class="disclaimer_body pt-5">
            <p class="mb-3 text-sm">
              I hereby acknowledge and agree to the following disclaimer
            </p>
            <div v-if="iframeSrc_Disclaimer">
              <iframe
                ref="iframe"
                :src="iframeSrc_Disclaimer"
                frameborder="0"
                width="100%"
              ></iframe>
            </div>
            <div class="pt-4">
              <div class="flex items-center text-sm pb-1">
                <button
                  class="mr-1 border-0 focus:outline-none bg-transparent underline"
                  style="color: var(--activeTextColor)"
                  type="button"
                  @click="downloadDisclaimer"
                >
                  Click here
                </button>
                to download the Disclaimer
              </div>
              <div>
                <input
                  id="terms_checked_disclaimer"
                  class="__disclaimer_agree_checkbox"
                  type="checkbox"
                  v-model="terms_checked_disclaimer"
                />
                <label for="terms_checked_disclaimer" class="ml-2 text-sm">
                  I agree
                </label>
              </div>
              <div>
                <p class="text-xs pt-1">
                  Note: If you checked "I agree" and clicked on "Agree" means
                  you are accepted our terms & conditions and signing to the
                  agreement.
                </p>
              </div>
            </div>
          </div>
          <div
            class="disclaimer_footer flex items-center pt-2"
            style="gap: 10px"
          >
            <div>
              <button
                class="agreebtn text-white active:bg-blueGray-600 text-xs font-semibold uppercase px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click="disclaimer_agreed"
              >
                agree
              </button>
              <a class="hidden" href="" id="toDownload_Disclaimer"></a>
            </div>
            <div>
              <button
                class="cancelbtn border active:bg-blueGray-600 text-xs font-semibold uppercase px-5 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                @click="$emit('disagreed')"
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import Loader from "../../layouts/Loader.vue";
import { PDFDocument } from "pdf-lib";
import DataServiceUrl from "../../config";
export default {
  emits: ["disagreed"],
  props: {
    login_Response_data: {
      type: Object,
    },
    agreed: Function,
  },
  data() {
    return {
      terms_checked: false,
      terms_checked_disclaimer: false,
      userName: "",
      roleName: "",
      userId: null,
      loader: false,
      iframeSrc_Agreement: "",
      iframeSrc_Disclaimer: "",
      showAgreement: false,
      agreementTemplateId: null,
      disclaimerTemplateId: null,
      login_data: this.login_Response_data,
      agreementCounter: 0,
      docType: "",
    };
  },
  components: {
    Loader,
  },
  methods: {
    async agreement_agreed() {
      if (this.terms_checked) {
        try {
          const pdfDoc = await PDFDocument.load(this.byteArray);
          const page = pdfDoc.addPage();
          const { height } = page.getSize();
          page.drawText("Signature:", { x: 50, y: height - 50, size: 13 });

          //appending userName and current date
          page.drawText(this.userName, { x: 50, y: height - 80, size: 13 });
          let current_date = new Date().toString();
          page.drawText(current_date, { x: 50, y: height - 100, size: 13 });
          // Serialize the PDFDocument to bytes (a Uint8Array)
          const pdfBytes = await pdfDoc.save();
          var new_file = new Blob([pdfBytes], {
            type: "application/pdf;base64",
          });
        } catch (err) {
          this.$toast.error(`CORRUPTED FILE`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.agreementCounter++;
          this.showAgreementAndDisclaimers(
            this.login_data.data.agreement[this.agreementCounter]
          );
          return false;
        }

        try {
          // req. body
          const formData = new FormData();
          formData.append("agreement", new_file, "Agreement.pdf");
          formData.append("signed", true);
          formData.append("agreementTemplateId", this.agreementTemplateId);
          formData.append("userId", this.userId);
          this.loader = true;
          const tokenDetails = this.$serviceHelpers.getDetails("670023242:az");
          const { access_token } = tokenDetails;
          let path =
            DataServiceUrl.HOST_URL.DATASERVICES_URL +
            "signedagreement/saveorupdate";
          let headers = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization":`Bearer ${ access_token }`
            },
          };
          let res = await axios.post(path, formData, headers);
          if (res.status == 200) {
            console.log("response", res.data);
            // download pdf
            // const link = document.getElementById("toDownload_Agreement");
            // link.href = URL.createObjectURL(new_file);
            // link.download = "Agreement.pdf";
            // link.click();
            this.loader = false;
            // this.showAgreement = false;
            this.agreementCounter++;
            this.showAgreementAndDisclaimers(
              this.login_data.data.agreement[this.agreementCounter]
            );
          }
        } catch (err) {
          this.loader = false;
          this.$toast.error(err, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      } else {
        this.$toast.error(`Please click on 'I agree' to continue`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async downloadAgreement() {
      this.loader = true;
      try {

        const pdfDoc = await PDFDocument.load(this.byteArray);
        const page = pdfDoc.addPage();
        const { height } = page.getSize();
        page.drawText("Signature:", { x: 50, y: height - 50, size: 13 });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();
        var new_file = new Blob([pdfBytes], { type: "application/pdf;base64" });

        const link = document.getElementById("toDownload_Agreement");
        link.href = URL.createObjectURL(new_file);
        link.download = "Agreement.pdf";
        link.click();
        this.loader = false;
      } catch (err) {
        this.loader = false;
        this.$toast.error(`${err}`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async generatingURL(pdf) {
      try {
        this.byteArray = new Uint8Array(pdf);
        var file = new Blob([this.byteArray], { type: "application/pdf;base64" });
        return URL.createObjectURL(file);
      } catch (error) {
        console.log("error in generating : ", error)
        return "";
      }
    },
    async showAgreementAndDisclaimers(agreement) {
      console.log("show agree and dis", agreement);
      if (agreement && agreement.template && agreement.template.data) {
        this.docType = agreement.sub_type.toUpperCase();
        let agreement_URL = await this.generatingURL(agreement.template.data);
        console.log("agreement: ", agreement_URL);
        if (agreement_URL) {
          this.iframeSrc_Agreement = agreement_URL;
          //getting agreementTemplateId
          this.agreementTemplateId = agreement.agreement_template_id;
        } else {
          this.agreementCounter++;
          this.showAgreementAndDisclaimers(
            this.login_data.data.agreement[this.agreementCounter]
          );
        }
      } else {
        delete this.login_Response_data.data.agreement;
        delete this.login_Response_data.data.signed;
        this.agreed(this.login_Response_data.data);
      }
    },
  },
  async mounted() {
    let userDetails = this.login_data.data.data.user;
    console.log("agreement : ", this.login_data.data.agreement);
    if (
      this.login_data.data.agreement &&
      this.login_data.data.agreement.length
    ) {
      //getting user name
      this.userName = userDetails.user_name;
      //getting userId
      this.userId = userDetails.user_id;
      //getting role name and checking what to show
      this.roleName = userDetails.user_roles.role_name;
      this.showAgreement = true;
      this.showAgreementAndDisclaimers(
        this.login_data.data.agreement[this.agreementCounter]
      );
    } else {
      delete this.login_Response_data.data.agreement;
      delete this.login_Response_data.data.signed;
      this.agreed(this.login_Response_data.data);
    }
  },
};
</script>
<style scoped>
.__disclaimer_agree_checkbox {
  -webkit-appearance: checkbox !important;
}

.disclaimerDiv {
  background: var(--sidebarbg);
  color: var(--textColor);
}

.pageHeading {
  color: var(--activeTextColor);
}

.agreebtn {
  background: var(--activeTextColor);
  color: #fff;
}

.cancelbtn {
  color: var(--activeTextColor);
  border-color: currentColor;
}

.disclaimerText {
  display: -webkit-box;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

iframe {
  height: 50vh;
}
</style>
